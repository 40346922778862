<template>
  <div>
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายการลูกค้าบริษัท ({{ rows | numeral("0,0") }})
          </h1>
        </b-col>
        <b-col xl="8" class="text-right d-flex justify-content-end">
          <b-input-group class="panel-input-serach">
            <b-form-input
              class="input-serach"
              placeholder="ค้นหาด้วย อีเมล, ชื่อ, เบอร์โทรศัพท์"
              v-model="filter.Search"
              @keyup="handleSearch"
            ></b-form-input>
            <b-input-group-prepend @click="btnSearch">
              <span class="icon-input m-auto pr-2">
                <font-awesome-icon icon="search" title="View" />
              </span>
            </b-input-group-prepend>
          </b-input-group>
          <!-- <b-button class="btn-filter" @click="showModal = true">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">Export </span>
          </b-button> -->
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline"
              >ค้นหา ({{
                countStatus +
                  countTier +
                  countStartdate +
                  countEnddate +
                  countCashback +
                  countSale +
                  countPoint +
                  countBankApproveStatusId+
                  countPdpa
              }})</span
            >
          </b-button>
          <b-dropdown class="ml-1 lang-dd border-0" right>
            <b-dropdown-item-button @click="exportData()"
              >ส่งออกข้อมูล</b-dropdown-item-button
            >
          </b-dropdown>
        </b-col>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="ค้นหาแบบละเอียด"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-link px-0"
              @click="onClearFilter()"
            >
              ล้างค่า
            </button>
          </div>
          <InputSelect
            class="mb-4"
            title="เรียง"
            name="Sortby"
            :options="optionsSort"
            v-model="filter.SortByOrderOrId"
            @onDataChange="handleChangeSort"
            valueField="id"
            textField="name"
          />

          <InputSelect
            class="mb-4"
            title="ระดับ"
            name="tier"
            :options="optionsTier"
            v-model="filter.tier"
            @onDataChange="handleChangeTier"
            valueField="id"
            textField="name"
          />

          <InputSelect
            class="mb-4"
            title="สถานะ"
            name="status"
            :options="optionsStatus"
            v-model="filter.status"
            @onDataChange="handleChangeStatus"
            valueField="value"
            textField="text"
          />

          <InputSelect
            class="mb-4"
            title="สถานะบัญชี"
            name="BankApproveStatusId"
            :options="optionsBankApproveStatusId"
            v-model="filter.BankApproveStatusId"
            @onDataChange="handleChangeBankApproveStatusId"
            valueField="id"
            textField="name"
          />

          <InputSelect
            class="mb-4"
            title="PDPA"
            name="Pdpa"
            :options="optionsPdpa"
            v-model="filter.approveConsent"
            @onDataChange="handleChangePdpa"
            valueField="value"
            textField="text"
          />
          
          <label class="label-text">วันสมัครสมาชิกเริ่มต้น</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.StartDate"
          ></datetime>

          <label class="label-text">วันสมัครสมาชิกสิ้นสุด</label>
          <datetime
            placeholder="กรุณากรอกวันที่"
            class="date-picker mb-3"
            format="dd MMM yyyy"
            v-model="filter.EndDate"
          ></datetime>

          <!-- <b-row>
            <b-col>
              <InputText
                textFloat="คะแนนเริ่มต้น"
                placeholder="คะแนนเริ่มต้น"
                type="text"
                name="pointstart"
                v-model="filter.MinPoint"
              />
            </b-col>
            <span class="lh-60">-</span>
            <b-col>
              <InputText
                textFloat="คะแนนสิ้นสุด"
                placeholder="คะแนนสิ้นสุด"
                type="text"
                name="pointend"
                v-model="filter.MaxPoint"
              />
            </b-col>
          </b-row> -->

          <b-row>
            <b-col>
              <InputText
                textFloat="ยอดขายเริ่มต้น"
                placeholder="ยอดขายเริ่มต้น"
                type="text"
                name="salestart"
                v-model="filter.MinSale"
              />
            </b-col>
            <span class="lh-60">-</span>
            <b-col>
              <InputText
                textFloat="ยอดขายสิ้นสุด"
                placeholder="ยอดขายสิ้นสุด"
                type="text"
                name="saleend"
                v-model="filter.MaxSale"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <InputText
                textFloat="เงินคืนเริ่มต้น"
                placeholder="เงินคืนเริ่มต้น"
                type="text"
                name="comstart"
                v-model="filter.MinCashback"
              />
            </b-col>
            <span class="lh-60">-</span>
            <b-col>
              <InputText
                textFloat="เงินคืนสิ้นสุด"
                placeholder="เงินคืนสิ้นสุด"
                type="text"
                name="comend"
                v-model="filter.MaxCashback"
              />
            </b-col>
          </b-row>

          <div class="text-center">
            <p class="text-danger" v-if="errorDate">
              วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
            </p>
          </div>

          <div class="text-center mt-4">
            <button
              type="button"
              class="btn btn-purple button"
              @click="getDataByStatus"
            >
              ค้นหา
            </button>
          </div>
        </div>
      </b-sidebar>

      <div class="mt-3 bg-white">
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col>
            <b-table
              class="table-list"
              striped
              responsive
              hover
              :items="items"
              :fields="fields"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(createdTime)="data">
                <span>{{
                  new Date(data.item.createdTime) | moment($formatDate)
                }}</span>
              </template>
              <template v-slot:cell(firstname)="data">
                <div class="row no-gutters">
                  <div class="col-12">
                    <div class="review-shortdesc">
                      <p class="font-weight-bold mb-1">
                        {{ data.item.firstname }} {{ data.item.lastname }}
                      </p>
                      <p class="m-0">
                        {{ data.item.telephone }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:cell(point)="data">
                <p class="m-0">{{ data.item.point | numeral("0,0") }}</p>
              </template>
              <template v-slot:cell(tier)="data">
                <p class="m-0">-</p>
              </template>
              <template v-slot:cell(sales)="data">
                <p class="m-0">฿ {{ data.item.sales | numeral("0,0.00") }}</p>
              </template>
              <template v-slot:cell(cashBack)="data">
                <p class="m-0">
                  ฿ {{ data.item.cashBack | numeral("0,0.00") }}
                </p>
              </template>
              <template v-slot:cell(approveConsent)="data">
                <div v-if="data.item.approveConsent" class="text-success">ยินยอม</div>
                <div v-else class="text-danger">ไม่ยินยอม</div>
              </template>
              <template v-slot:cell(statusId)="data">
                <div v-if="data.item.statusId == 0" class="text-danger">
                  ปิดใช้งาน
                </div>
                <div v-else-if="data.item.statusId == 1" class="text-success">
                  เปิดใช้งาน
                </div>
                <div v-else class="text-warning">รอการยืนยัน</div>
              </template>
              <template v-slot:cell(genderId)="data">
                <div v-if="data.item.genderId == 1">ชาย</div>
                <div v-else-if="data.item.genderId == 2">หญิง</div>
                <div v-else class="">N/A</div>
              </template>
              <template v-slot:cell(id)="data">
                <div class="d-flex justify-content-center">
                  <b-button variant="link" class="px-1 py-0">
                    <router-link
                      :to="'/affiliate/details/' + data.item.userGUID"
                    >
                      ตรวจสอบ
                    </router-link>
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">
                  {{ totalRowMessage }}
                </p>
              </div>
            </div>

            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
      <b-modal
        v-model="showModal"
        centered
        hide-header
        hide-footer
        no-body
        @hide="handleModalHide"
      >
        <div class="pb-3">
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="showModal = false"
          >
            ×
          </button>
        </div>
        <div class="py-3">
          <InputText
            textFloat="Email"
            placeholder="กรุณากรอกอีเมล"
            type="text"
            name="pointend"
            v-model="email"
            isRequired
            :isValidate="$v.email.$error"
            :v="$v.email"
          />
        </div>
        <div class="form-inline justify-content-between mt-2">
          <b-button class="mr-2 btn-modal button" @click="showModal = false"
            >ยกเลิก</b-button
          >
          <b-button class="ml-2 btn btn-purple button" @click="exportData()"
            >Export</b-button
          >
        </div>
      </b-modal>
      <ModalAlertError ref="modalAlertError" :text="modalMessage" />
      <ModalAlert ref="modalAlert" :text="modalMessage" />
      <ModalLoading ref="modalLoading" :hasClose="false" />
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";
import * as moment from "moment/moment";

export default {
  name: "AffiliateIndex",
  components: {
    InputText,
    InputSelect,
    ModalLoading,
    ModalAlertError,
    ModalAlert,
  },
  validations: {
    email: { required, email },
  },
  data() {
    return {
      email: "",
      selected: null,
      showModal: false,
      options: [{ value: null, text: "สถานะ" }],
      statusList: [],
      modalMessage: "",
      activeItem: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "createdTime",
          label: "วันที่สมัครสมาชิก",
          class: "w-100px",
        },
        {
          key: "firstname",
          label: "ชื่อลูกค้า",
          class: "w-100px",
          tdClass: "text-left",
        },

        // {
        //   key: "point",
        //   label: "คะแนน",
        //   class: "w-100px",
        // },
        // {
        //   key: "sales",
        //   label: "ยอดขายสะสม",
        //   class: "w-100px",
        // },
        // {
        //   key: "cashBack",
        //   label: "เงินคืน",
        //   class: "w-100px",
        // },
        // {
        //   key: "genderId",
        //   label: "เพศ",
        //   class: "w-50px",
        // },
        // {
        //   key: "follower",
        //   label: "ลูกข่าย",
        //   class: "w-100px",
        // },
        {
          key: "email",
          label: "อีเมล",
          class: "w-50px",
        },
        {
          key: "tier",
          label: "ระดับ",
        },
        {
          key: "statusId",
          label: "สถานะ",
          class: "w-100px",
        },
        {
          key: "approveConsent",
          label: "PDPA",
          class: "w-100px",
        },
        // {
        //   key: "bankApproveStatus",
        //   label: "สถานะบัญชี",
        //   class: "w-100px",
        // },
        {
          key: "id",
          label: "",
          class: "w-100px",
        },
      ],
      items: [],
      isBusy: false,
      value: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        StartDate: null,
        EndDate: null,
        status: [],
        Status: [],
        Search: "",
        tier: [],
        Tier: [],
        MinPoint: 0,
        MaxPoint: 0,
        MinSale: 0,
        MaxSale: 0,
        MinCashback: 0,
        MaxCashback: 0,
        BankApproveStatusId: -1,
        SortByOrderOrId: 0,
        ApproveConsent: [],
        approveConsent: [],
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      totalRowMessage: "",
      isDisable: false,
      checkAll: false,
      selectAllCb: false,
      optionsTier: [
        { id: [], name: "ระดับ" },
        { id: 1, name: "Platinum" },
        { id: 2, name: "Gold" },
        { id: 3, name: "Silver" },
      ],
      optionsSort: [
        { id: 0, name: "เรียงจาก" },
        { id: 1, name: "ลูกข่าย สูงสุด" },
        { id: 2, name: "CB สูงสุด" },
        { id: 3, name: "Point สูงสุด" },
        { id: 4, name: "ยอดขาย สูงสุด" },
      ],
      optionsStatus: [
        { value: [], text: "กรุณาเลือกสถานะ" },
        { value: 0, text: "ปิดใช้งาน" },
        { value: 1, text: "เปิดใช้งาน" },
        { value: 2, text: "รอการยืนยัน" },
      ],
      optionsPdpa: [
        { value: [], text: "ทั้งหมด" },
        { value: 0, text: "ไม่ยินยอม" },
        { value: 1, text: "ยินยอม" },
      ],
      optionsBankApproveStatusId: [{ id: -1, name: "สถานะบัญชี" }],
      errorDate: false,
      timer: null,
    };
  },
  computed: {
    countTier: function() {
      var count = 0;
      if (this.filter.Tier.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStatus: function() {
      var count = 0;
      if (this.filter.Status.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countPdpa: function() {
      var count = 0;
      if (this.filter.ApproveConsent.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countBankApproveStatusId: function() {
      var count = 0;
      if (this.filter.BankApproveStatusId != -1) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStartdate: function() {
      var count = 0;
      if (this.filter.StartDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countEnddate: function() {
      var count = 0;

      if (this.filter.EndDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countPoint: function() {
      var count = 0;

      if (this.filter.MinPoint != 0 || this.filter.MaxPoint != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countSale: function() {
      var count = 0;

      if (this.filter.MinSale != 0 || this.filter.MaxSale != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countCashback: function() {
      var count = 0;

      if (this.filter.MinCashback != 0 || this.filter.MaxCashback != 0)
        count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
  created: async function() {
    await this.getListBankAccountApproveStatus();
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function() {
      this.isBusy = true;

      if (this.filter.MinPoint == "") this.filter.MinPoint = 0;
      if (this.filter.MinSale == "") this.filter.MinSale = 0;
      if (this.filter.MinCashback == "") this.filter.MinCashback = 0;
      if (this.filter.MaxPoint == "") this.filter.MaxPoint = 0;
      if (this.filter.MaxSale == "") this.filter.MaxSale = 0;
      if (this.filter.MaxCashback == "") this.filter.MaxCashback = 0;

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Affiliate/List`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        //this.statusList = resData.detail.overviewCount;

        this.isBusy = false;
      }
    },
    getListBankAccountApproveStatus: async function() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Affiliate/BankAccountApproveStatus`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.optionsBankApproveStatusId = this.optionsBankApproveStatusId.concat(
          resData.detail.dataList
        );
      }
    },
    getDataByStatus(status) {
      if (
        this.filter.StartDate > this.filter.EndDate &&
        this.filter.EndDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    onClearFilter() {
      this.filter.PageNo = 1;
      this.filter.PerPage = 10;
      this.filter.StartDate = "";
      this.filter.EndDate = "";
      this.filter.Status = [];
      this.filter.Search = "";
      this.filter.Tier = [];
      this.filter.MinPoint = 0;
      this.filter.MaxPoint = 0;
      this.filter.MinSale = 0;
      this.filter.MaxSale = 0;
      this.filter.MinCashback = 0;
      this.filter.MaxCashback = 0;
      this.filter.SortByOrderOrId = 0;
      this.filter.BankApproveStatusId = -1;
      this.filter.ApproveConsent = [];
      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getList();
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.PageNo = 1;
        this.getList();
      }, 800);
    },
    btnSearch() {
      this.filter.PageNo = 1;
      this.getList();
    },
    handleChangeSort: async function(value) {
      this.filter.SortByOrderOrId = value;
    },
    handleChangeTier: async function(value) {
      this.filter.Tier = [];
      if (typeof value == "number") {
        this.filter.Tier.push(value);
      }
    },
    handleChangeStatus: async function(value) {
      this.filter.Status = [];
      if (typeof value == "number") {
        this.filter.Status.push(value);
      }
    },
    handleChangePdpa: async function(value) {
      this.filter.ApproveConsent = [];
      if (typeof value == "number") {
        this.filter.ApproveConsent.push(value);
      }
    },
    handleChangeBankApproveStatusId: async function(value) {
      this.filter.BankApproveStatusId = value;
    },
    handleModalHide() {
      this.email = "";
      this.$v.email.$reset();
    },
    // async exportData() {
    //   console.log(this.$v);
    //   this.$v.email.$touch();
    //   if (this.$v.email.$error) {
    //     return;
    //   }
    //   this.$refs.modalLoading.show();
    //   const result = await this.$callApi(
    //     "post",
    //     `${this.$baseUrl}/api/Affiliate/Export`,
    //     null,
    //     this.$headers,
    //     { name: this.email }
    //   );

    //   if (result.result == 1) {
    //     this.modalMessage = result.message;
    //     this.$refs.modalAlert.show();
    //   } else {
    //     this.modalMessage = result.message;
    //     this.$refs.ModalAlertError.show();
    //   }
    //   this.$refs.modalLoading.hide();
    // },
    exportData: async function () {
      this.$refs.modalLoading.show();
      axios({
        url: `${this.$baseUrl}/api/Affiliate/Export`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: this.filter
      })
        .then((response) => {
          this.$refs.modalLoading.hide();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Affiliate-` + dateExcel + `.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.lh-60 {
  line-height: 60px;
}
::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}
::v-deep .lang-dd ul {
  width: 100px;
}
</style>
